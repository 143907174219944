import firebase from 'firebase/compat/app';

const firebaseConfig = {
    apiKey: "AIzaSyCZUWM5kLtGydsAkW4jVudeVYbdhfgYJsQ",
    authDomain: "gfgsite-b41de.firebaseapp.com",
    databaseURL: "https://gfgsite-b41de.firebaseio.com",
    projectId: "gfgsite-b41de",
    storageBucket: "gfgsite-b41de.appspot.com",
    messagingSenderId: "315497716801",
    appId: "1:315497716801:web:573752c48bf5dc12"
  };

firebase.initializeApp(firebaseConfig);
export default firebase;