import React from "react";
import Loading from "./loading";
import ErrorPage from "./errorpage";
import ITeamInfo from "../interfaces/ITeamInfo";
import { loadState } from "../utils/enums";
import FirebaseService from "../services/firebaseService";
import IMatchInfo from "../interfaces/IMatchInfo";

interface Props {}

interface State {
    teams: Array<ITeamInfo>,
    matches: Array<IMatchInfo>,
    loadState: loadState
}

export default class Invitational extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            teams: [],
            matches: [],
            loadState: loadState.loading
        }
    }

    render() {
        switch (this.state.loadState) {
            case loadState.loading:
                return <Loading />
            case loadState.found:
                return this.loaded();
            default:
                return <ErrorPage />
        }
    }

    loaded() {
        const teamRows = this.state.teams
        .sort((a, b) => b.score - a.score)
        .map(team => 
            <tr key={team.id}>
                <td title="Name">{team.name}</td>
                <td title="Score">{team.score}</td>
            </tr>
        );

        const groupStageRows = this.state.matches.filter(m => m.round <= 7)
        .map(match => {
            const roundTimes: { [id: number] : string} = {
                1: '1st March - 10th March',
                2: '1st March - 10th March',
                3: '1st March - 10th March',
                4: '11th March - 17th March',
                5: '11th March - 17th March',
                6: '18th March - 24th March',
                7: '18th March - 24th March'
            };
            const time = match.time === undefined ?
                roundTimes[match.round] :
                match.time.toDate().toLocaleString(navigator.language, {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                });

            const stream = match.stream === undefined ?
                'TBD' :
                <a href={`https://twitch.tv/${match.stream}`}>{match.stream}</a>

            const homeTeamName = this.state.teams.find(t => t.id === match.homeTeam)?.name ?? 'ERROR';
            const awayTeamName = this.state.teams.find(t => t.id === match.awayTeam)?.name ?? 'ERROR';
            return <tr key={match.id}>
                <td title="Home team">{homeTeamName}</td>
                <td title="Score">{match.homeResult ?? '-'}</td>
                <td title="Away team">{awayTeamName}</td>
                <td title="Score">{match.awayResult ?? '-'}</td>
                <td title="Time">{time}</td>
                <td title="Stream">{stream}</td>
            </tr>
        });

        const knockoutRows = this.state.matches.filter(m => m.round >= 8)
        .map(match => {
            const time = match.time === undefined ?
            "-" :
            match.time.toDate().toLocaleString(navigator.language, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            });

            const stream = match.stream === undefined ?
                'TBD' :
                <a href={`https://twitch.tv/${match.stream}`}>{match.stream}</a>
            
            const homeTeamName = this.state.teams.find(t => t.id === match.homeTeam)?.name ?? match.homeTeam;
            const awayTeamName = this.state.teams.find(t => t.id === match.awayTeam)?.name ?? match.awayTeam;
            
            const rows = [];
            if (match.matchName !== undefined) {
                rows.push(<tr><th colSpan={6}>{match.matchName}</th></tr>);
            }
            rows.push(<tr key={match.id}>
                    <td title="Team 1" className={match.homeResult > match.awayResult ? "winner" : ""}>{homeTeamName}</td>
                    <td title="Score">{match.homeResult ?? '-'}</td>
                    <td title="Team 2" className={match.awayResult > match.homeResult ? "winner" : ""}>{awayTeamName}</td>
                    <td title="Score">{match.awayResult ?? '-'}</td>
                    <td title="Time">{time}</td>
                    <td title="Stream">{stream}</td>
                </tr>)
            return rows;
        }).flat();

        return <div>
            <h1>GFG Invitational 2024</h1>
            <p>
                Congratulations to Team Spherical, the inaugural GFG Invitational champions!
            </p>
            <p>
                More goon tier Dota is in the works
                - <a href="https://twitter.com/gfgdota2">follow us on Twitter</a> to stay up to date.
            </p>
            <h2>Teams</h2>
            <table>
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Group stage score</th>
                    </tr>
                </thead>
                <tbody>
                    {teamRows}
                </tbody>
            </table>
            <h2>Schedule</h2>
            <h3>Knockout stage</h3>
            <table className={"max-width"}>
                <thead>
                    <tr>
                        <th scope="col">Team 1</th>
                        <th scope="col">Score</th>
                        <th scope="col">Team 2</th>
                        <th scope="col">Score</th>
                        <th scope="col">Time</th>
                        <th scope="col">Stream</th>
                    </tr>
                </thead>
                <tbody>
                    {knockoutRows}
                </tbody>
            </table>
            <h3>Group stage</h3>
            <table className={"max-width"}>
                <thead>
                    <tr>
                        <th scope="col">Home team</th>
                        <th scope="col">Score</th>
                        <th scope="col">Away team</th>
                        <th scope="col">Score</th>
                        <th scope="col">Time</th>
                        <th scope="col">Stream</th>
                    </tr>
                </thead>
                <tbody>
                    {groupStageRows}
                </tbody>
            </table>
        </div>
    }

    async componentDidMount() {
        const firebaseService = new FirebaseService();
        const teams = await firebaseService.getInvitationalTeams();
        const matches = await firebaseService.getInvitationalMatches();
        for (const team of teams) {
            let score = matches.filter(m => m.homeTeam === team.id && m.round <= 7).reduce((prev, curr) => prev + (curr.homeResult ?? 0), 0);
            score += matches.filter(m => m.awayTeam === team.id && m.round <= 7).reduce((prev, curr) => prev + (curr.awayResult ?? 0), 0);
            team.score = score;
        }
        this.setState({
            teams: teams,
            matches: matches,
            loadState: loadState.found
        });
    }
}