import React from 'react'
import './nav.css'

export default function Nav() {
    return (
        <div className="nav-container">
            <div className="main-link-container">
                <a href="/" className="main-link">GF+G</a>
            </div>
            <div className="sub-link-container mobile-optional">
                <a href="/players" className="sub-link">Players</a>
            </div>
            <div className="sub-link-container">
                <a href="/invitational" className="sub-link">Invitational</a>
            </div>
            <div className="sub-link-container mobile-optional">
                <a href="/contact" className="sub-link">Contact</a>
            </div>
        </div>
    )
}