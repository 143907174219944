import React from 'react';
import FirebaseService from '../services/firebaseService';
import IPlayerInfo from '../interfaces/IPlayerInfo';
import NotFound from './notfound';
import ErrorPage from './errorpage';
import Loading from './loading';
import { loadState } from '../utils/enums';

interface Props {
    match: {
        params: {
            playerId: string
        }
    }
}

interface State {
    playerInfo: IPlayerInfo,
    loadState: loadState
}

export default class PlayerPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            playerInfo: {
                id: this.props.match.params.playerId,
                tag: "",
                name: "",
                profileImg: "",
                pos: "",
                heroes: [],
                biog: "",
                key: ""
            },
            loadState: loadState.loading
        }
    }

    renderSection(sectionName: string, text: string | Array<string>) {
        if (text == null) {
            return;
        }
        if (typeof text === "object") {
            text = text.join(", ");
        }
        return (
            <div className="section-container">
                <p>
                    <span className="section-title">{sectionName + ": "}</span>
                    <span>{text}</span>
                </p>
            </div>
        )
    }

    found() {
        return (
            <div className="page-container">
                <h1 className="page-title">{this.state.playerInfo.tag}</h1>
                {this.renderSection("Real name", this.state.playerInfo.name)}
                <img src={this.state.playerInfo.profileImg} alt={this.state.playerInfo.tag + "'s profile image"}/>
                {this.renderSection("Position", this.state.playerInfo.pos)}
                {this.renderSection("Featured heroes", this.state.playerInfo.heroes)}
                <p className="biography">{this.state.playerInfo.biog}</p>
            </div>
        )
    }

    render() {
        switch(this.state.loadState) {
            case loadState.loading:
                return <Loading />;
            case loadState.found:
                return this.found();
            case loadState.notFound:
                return <NotFound />;
            case loadState.error:
                return <ErrorPage />;
        }
    }

    componentDidMount() {
        const firebaseService = new FirebaseService();
        firebaseService.getPlayerInfo(this.props.match.params.playerId)
            .then(playerInfo => {
                this.setState({
                    playerInfo: playerInfo,
                    loadState: loadState.found
                });
            })
            .catch((e) => {
                if (e === "Player not found") {
                    this.setState({
                        loadState: loadState.notFound
                    });
                } else {
                    this.setState({
                        loadState: loadState.error
                    });
                }
            })
    }
}