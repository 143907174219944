import React from 'react';
import { loadState } from '../utils/enums';
import Loading from './loading';
import ErrorPage from './errorpage';
import './list.css';
import IInfo from '../interfaces/IInfo';
import FirebaseService from '../services/firebaseService';
import IPlayerInfo from '../interfaces/IPlayerInfo';
import ITeamInfo from '../interfaces/ITeamInfo';

interface State<Info extends IInfo> {
    items: Array<Info>,
    loadState: loadState
}

interface Props<Info extends IInfo> {
    card: React.ComponentType<{ info: Info }>
}

abstract class CardList<Info extends IInfo> extends React.Component<Props<Info>, State<Info>> {
    constructor(props: Props<Info>) {
        super(props);
        this.state = {
            items: [],
            loadState: loadState.loading
        };
    }

    render() {
        switch (this.state.loadState) {
            case loadState.loading:
                return <Loading />
            case loadState.found:
                return this.loaded();
            default:
                return <ErrorPage />
        }
    }

    loaded() {
        const cards = this.state.items.map(item => 
            <li key={item.key}><this.props.card info={item} /></li>
        );
        return (
            <div className="list-container">
                <ul className="card-list">
                    {cards}
                </ul>
            </div>
        )
    }

    abstract componentDidMount(): Promise<void>
}

export class PlayerList extends CardList<IPlayerInfo> {
    async componentDidMount() {
        const firebaseService = new FirebaseService();
        const players = await firebaseService.getPlayers();
        this.setState({
            items: players,
            loadState: loadState.found
        });
    }
}

export class TeamList extends CardList<ITeamInfo> {
    async componentDidMount(): Promise<void> {
        const firebaseService = new FirebaseService();
        const teams = await firebaseService.getInvitationalTeams();
        this.setState({
            items: teams,
            loadState: loadState.found
        })
    }
}