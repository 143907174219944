import React from 'react';
import gfgLogo from '../static/img/GFGLOGOTRANSPARENT.png';
import './frontpage.css';

export default function FrontPage() {
    return (
        <div className="frontpage-container">
            <img className="logo" src={gfgLogo} alt="GFG Esports logo"/>
        </div>
    )
}