import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Nav from './components/nav';
import PlayerPage from './components/playerpage';
import Contact from './components/contact';
import FrontPage from './components/frontpage';
import NotFound from './components/notfound';
import Invitational from './components/invitational';
import {PlayerList} from './components/cardList';
import PlayerCard from './components/playercard';
import TeamPage from './components/teamPage';

const App: React.FC = () => {
  return (
    <div className="App">
      <Nav />
      <div id="main-content">
        <Switch>
          <Route exact path="/" component={FrontPage} />
          <Route exact path="/players">
            <PlayerList card={PlayerCard} />
          </Route>
          <Route exact path="/players/:playerId" component={PlayerPage} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/invitational" component={Invitational} />
          <Route exact path="/invitational/teams/:teamId" component={TeamPage} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
