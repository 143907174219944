import React from "react"
import ITeamInfo from "../interfaces/ITeamInfo"
import { loadState } from "../utils/enums"
import Loading from "./loading"
import NotFound from "./notfound"
import ErrorPage from "./errorpage"
import FirebaseService from "../services/firebaseService"

interface Props {
    match: {
        params: {
            teamId: string
        }
    }
}

interface State {
    teamInfo: ITeamInfo,
    loadState: loadState
}

export default class TeamPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            teamInfo: {
                id: this.props.match.params.teamId,
                name: "",
                profileImg: "",
                players: [],
                biog: "",
                key: "",
                score: 0
            },
            loadState: loadState.loading
        }
    }
    
    render() {
        switch(this.state.loadState) {
            case loadState.loading:
                return <Loading />;
            case loadState.found:
                return this.found();
            case loadState.notFound:
                return <NotFound />;
            case loadState.error:
                return <ErrorPage />;
        }
    }

    found() {
        return (
            <div className="page-container">
                <h1 className="page-title">{this.state.teamInfo.name}</h1>
                {this.renderSection("Players", this.state.teamInfo.players)}
                <img src={this.state.teamInfo.profileImg} alt={this.state.teamInfo.name + "'s profile image"}/>
                <p className="biography">{this.state.teamInfo.biog}</p>
            </div>
        )
    }

    renderSection(sectionName: string, text: string | Array<string>) {
        if (text == null) {
            return;
        }
        if (typeof text === "object") {
            text = text.join(", ");
        }
        return (
            <div className="section-container">
                <p>
                    <span className="section-title">{sectionName + ": "}</span>
                    <span>{text}</span>
                </p>
            </div>
        )
    }

    componentDidMount() {
        const firebaseService = new FirebaseService();
        firebaseService.getTeamInfo(this.props.match.params.teamId)
            .then(teamInfo => {
                this.setState({
                    teamInfo: teamInfo,
                    loadState: loadState.found
                });
            })
            .catch((e) => {
                if (e === "Team not found") {
                    this.setState({
                        loadState: loadState.notFound
                    });
                } else {
                    this.setState({
                        loadState: loadState.error
                    });
                }
            })
    }
}