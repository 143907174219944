import React from 'react';
import IPlayerInfo from '../interfaces/IPlayerInfo';
import './card.css';

interface Props {
    info: IPlayerInfo
}

export default function PlayerCard(props: Props) {
    return(
        <a href={"/players/" + props.info.id} className="card">
            <h3 className="card-name">{props.info.tag}</h3>
            <img src={props.info.profileImg} alt={props.info.tag + "'s profile image"} className="card-image" />
        </a>
    )
}