import firebase from '../firebase';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import IPlayerInfo from '../interfaces/IPlayerInfo';
import ITeamInfo from '../interfaces/ITeamInfo';
import IInfo from '../interfaces/IInfo';
import IMatchInfo from '../interfaces/IMatchInfo';

export default class FirebaseService {
    private database: firebase.firestore.Firestore;
    private storage: firebase.storage.Storage;

    constructor() {
        this.database = firebase.firestore();
        this.storage = firebase.storage();
    }

    private getDocument(collection: string, doc: string): Promise<firebase.firestore.DocumentSnapshot> {
        let ref = this.database.collection(collection).doc(doc);
        return ref.get();
    }

    private getCollection(collection: string): Promise<firebase.firestore.QuerySnapshot> {
        let ref = this.database.collection(collection);
        return ref.get();
    }

    public async getPlayerInfo(playerId: string): Promise<IPlayerInfo> {
        const snapshot = await this.getDocument("players", playerId);
        if (!snapshot.exists) {
            return Promise.reject("Player not found");
        }
        return this.infoFromDoc((snapshot.data() as IPlayerInfo), playerId);
    }

    public async getPlayers(): Promise<Array<IPlayerInfo>> {
        const players = await this.getAndMapCollection<IPlayerInfo>("players", this.infoFromDoc.bind(this), "tag");
        players.sort((a, b) => {
            return a.pos < b.pos ? -1 : 1;
        });
        return players;
    }
    
    public async getInvitationalTeams(): Promise<Array<ITeamInfo>> {
        const teams = await this.getAndMapCollection<ITeamInfo>("invitationalTeams", this.infoFromDoc.bind(this), "name");
        teams.sort((a, b) => {
            return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
        });
        return teams;
    }

    public async getTeamInfo(teamId: string): Promise<ITeamInfo> {
        const snapshot = await this.getDocument("invitationalTeams", teamId);
        if (!snapshot.exists) {
            return Promise.reject("Team not found");
        }
        return this.infoFromDoc((snapshot.data() as ITeamInfo), teamId);
    }

    public async getTeamPlayers(team: string): Promise<Array<IPlayerInfo>> {
        const teamCollection = team === "gfg" ? "players" : `${team}-players`;
        const players = await this.getAndMapCollection<IPlayerInfo>(teamCollection, this.infoFromDoc.bind(this), "tag");
        players.sort((a, b) => {
            return a.pos < b.pos ? -1 : 1;
        });
        return players;
    }
    
    public async getInvitationalMatches(): Promise<Array<IMatchInfo>> {
        const matches = await this.getAndMapCollection<IMatchInfo>("matches", this.infoFromDoc.bind(this), "id");
        matches.sort((a, b) => {
            if (a.round >= 8 || b.round >= 8) {
                return a.round - b.round;
            }
            if (a.time !== undefined && b.time !== undefined) {
                return a.time < b.time ? -1 : 1;
            }
            return a.round - b.round
        });
        return matches;
    }

    private async getAndMapCollection<To extends IInfo>(collection: string, map: Function, key: string): Promise<Array<To>> {
        const snapshot = await this.getCollection(collection);
        let items = new Array<To>();
        for (const doc of snapshot.docs) {
            const item = await map((doc.data()), doc.id);
            item.key = item[key];
            items.push(item);
        };
        return items;
    }

    private async infoFromDoc<Q extends fillableDoc>(doc: Q, id: string): Promise<Q> {
        let downloadUrl: string = "";
        if (doc.profileImg) {
            downloadUrl = await this.storage.refFromURL(doc.profileImg).getDownloadURL();
        }
        doc.profileImg = downloadUrl;
        doc.id = id;
        return doc;
    }

    
}

interface fillableDoc {
    profileImg: string,
    id: string
}